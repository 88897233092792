import React from "react";
import redpaymentsSVG from "../../../images/redpayments.svg";
import visaSVG from "../../../images/visa-master.svg";
import amexSVG from "../../../images/amex.svg";

export default ({ tab }) => {
  const { backgroundColor, img } = makeStyles(tab);

  return (
    <span style={{ backgroundColor }}>
      {img ? <img src={img} /> : tab.split(" ").join("\n")}
    </span>
  );
};

const makeStyles = (value) => {
  switch (value.toLowerCase()) {
    case "eftpos":
      return { backgroundColor: "#f95c15" };
    case "redpayments":
      return { backgroundColor: "#f32c3b", img: redpaymentsSVG };
    case "eftpos offline":
      return { backgroundColor: "#feae32" };
    case "visa/master":
      return { backgroundColor: "#3abeb9", img: visaSVG };
    case "amex":
      return { backgroundColor: "#2358e7", img: amexSVG };
    case "cash":
      return { backgroundColor: "#6b7ee9" };
    default:
      return { backgroundColor: "#7162DF" };
  }
};
